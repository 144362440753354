import React from "react";
import styles from "./ExpandableExperienceCard.module.scss";
import classnames from "classnames";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import Location from "@mui/icons-material/LocationOn";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "../../reducers/ExpandableCardReducer";

const ExpandableExperienceCard = ({
  title,
  text,
  location,
  website,
  content,
  logo,
  id,
  skills,
  link,
}) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  const open = useSelector((state) => state.ExpandableCard[id]);
  const handleShowSubsection = () => {
    dispatch(setOpen(id));
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerOpen]: open,
        [styles.containerDark]: isDarkMode,
      })}
      key={id}
    >
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <div className={styles.headercta}>
          <p className={styles.text}>{text}</p>

          <IconButton
            color="primary"
            aria-label="show-hide-icon"
            onClick={handleShowSubsection}
          >
            {open ? (
              <RemoveIcon className={styles.hideIcon} fontSize="medium" />
            ) : (
              <AddIcon className={styles.showIcon} fontSize="medium" />
            )}
          </IconButton>
        </div>
      </div>
      {open && (
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <span className={styles.location}>
              <Location fontSize="small" className={styles.locationIcon} />{" "}
              {location}
            </span>
            <a
              href={link}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <LinkIcon fontSize="small" /> {website}
            </a>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subsectionContent}>
              <p className={styles.contextText}>{content}</p>
              <div className={styles.skills}>
                {skills.map((e, index) => (
                  <div className={styles.skill} key={index}>
                    {e}{" "}
                  </div>
                ))}
              </div>{" "}
            </div>
            <div className={styles.logoContainer}>{logo}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableExperienceCard;
