import UwLogo from "../../assets/images/uw.png";
import QaLogo from "../../assets/images/qa.png";
import AndLogo from "../../assets/images/and.png";


export const exp = [
    {
        id: "uw",
        title: "Software Engineer @ Utility Warehouse ",
        text: "2022 - Present",
        location: "Remote",
        website: "www.uw.co.uk",
        link: "https://uw.co.uk/",
        content: "FullStack developer on the Customer support team working to deliver operational excellence. Working with broad range of technologies to deliver full SDLC. Currently focusing on upskilling in Golang and Kubernetes as part of my role",
        skills: ["Go", "React", "Typescript", "Graphql", "GRPC", "Kubernetes", "E2E", "TDD", "Docker", "Git"],
        logo: <img src={UwLogo} alt="uw-logo" />
    },
    {
        id: "and-digital",
        title: "Software Engineer @ And Digital ",
        text: "2019 - 2022",
        location: "Manchester",
        website: "www.and.digital",
        link: "https://www.and.digital/",
        content: "FullStack developer on projects for leading businesses such as Boohoo group, SKY, Very , Business Growth Hub and more. Gained confidence over a broad range of tech stack while working with different clients and diverse project scopes and requirements",
        skills: ["React", "Node.js", "Java", "Graphql", "Typescript", "E2E", "TDD", "Figma", "Git"],
        logo: <img src={AndLogo} alt="and-logo" />
    },
    {
        id: "qa-consulting",
        title: "Graduate IT Consultant @ QA Consulting ",
        text: "2018 - 2019",
        location: "Manchester",
        website: "www.qa.com",
        link: "https://www.qa.com/",
        content: "Completed 3 months of intensive training to apply and practice theory and understanding of SDLC. Qualified as Prince 2 Practitoner and worked with clients to deliver projects from scratch. Predominantly used MEAN stack whilst placed on client projects. ",
        skills: ["Java", "Springboot", "Typescript", "React", "Node.js", "Git", "Prince2 Qualified"],
        logo: <img src={QaLogo} alt="qa-logo" />
    }]