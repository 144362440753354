import React from "react";
import { Button } from "@mui/material";
import styles from "../Coffee/Coffee.module.scss";

const Coffee = () => {
  return (
    <Button
      variant="contained"
      className={styles.coffeeButton}
      target="_blank"
      href="https://www.buymeacoffee.com/tabassummulla"
    >
      <img
        className={styles.coffeeIcon}
        src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
        alt="Buy me a coffee"
      />
      <p className={styles.text}>Buy me a coffee</p>
    </Button>
  );
};

export default Coffee;
