
export const HobbiesInfo = [
  "to travel 🌏",
  "to cook/ to eat 🍩",
  "to watch Sci-Fi Movies 🎥",
  "creating digital art 🎨",
];


export const skills = [
  {
    text: "React",
    icon: <i className="devicon-react-original colored"></i>,
  },
  {
    text: "Node.js",
    icon: <i className="devicon-nodejs-plain colored"></i>,
  },
  {
    text: "Go",
    icon: <i class="devicon-go-original-wordmark colored"></i>
  },
  {
    text: "Kubernetes",
    icon:
      <i class="devicon-kubernetes-plain colored"></i>
  },
  {
    text: "Docker",
    icon: <i class="devicon-docker-plain colored"></i>
  },
  {
    text: "AWS", icon: <i className="devicon-amazonwebservices-original colored"></i>,
  },
  {
    text: "Html",
    icon: <i className="devicon-html5-plain colored"></i>,
  },
  {
    text: "Sass",
    icon: <i className="devicon-sass-original colored"></i>,
  },
  {
    text: "CSS",
    icon: <i className="devicon-css3-plain colored"></i>,
  },
  {
    text: "Java",
    icon: <i className="devicon-java-plain colored"></i>,
  },
  {
    text: "Javascript",
    icon: <i className="devicon-javascript-plain colored"></i>,
  },
  {
    text: "Redux",
    icon: <i className="devicon-redux-original colored" />,
  },
  {
    text: "Graphql", icon: <i className="devicon-graphql-plain colored"></i>,
  },
  {
    text: "Postgresql",
    icon: <i className="devicon-postgresql-plain colored"></i>,
  },
  {
    text: "Mongodb",
    icon: <i className="devicon-mongodb-plain colored"></i>,
  },
  {
    text: "Mocha",
    icon: <i className="devicon-mocha-plain colored"></i>,
  },
  {
    text: "Git",
    icon: <i className="devicon-git-plain colored"></i>,
  },
  {
    text: "Figma", icon: <i className="devicon-figma-plain colored"></i>
  },
];



export const content = `You can call me Tabs! I am a Software Engineer, with 4 years of experience in the Industry. I would best like to describe myself as a FullStack developer with expertise in React and Node.js. I have varied experience of work from Consulting to working with In-house Companies.${<br></br>}I have many hobbies but my top 3 would be Travelling, Cooking/Eating and Fitness!`