export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const emailConfig = {
    serviceID: process.env.REACT_EMAIL_JS_SERVICE_ID,
    templateID: process.env.REACT_EMAIL_JS_TEMPLATE_ID,
    url: "https://api.emailjs.com/api/v1.0/email/send",
    publicKey: process.env.REACT_EMAIL_JS_PUBLIC_KEY
}

export const successMessage = () => {
    return `Thank you for reaching out! Your message just showed up in my inbox. Talk to you soon!`
}