import React from "react";
import { useSelector } from "react-redux";
import styles from "./SkillsMatrix.module.scss";
import classnames from "classnames";

const SkillsMatrix = ({ skills }) => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.header}>
        <h1 className={styles.title}>/ My Skills</h1>
      </div>
      <div className={styles.content}>
        {skills.map((e, i) => (
          <div className={styles.skills} key={i}>
            {e.icon}
            <p className={styles.text}>{e.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsMatrix;
