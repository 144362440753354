import React, { useState } from "react";
import styles from "./Footer.module.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import Coffee from "../Coffee/Coffee";
import CreditsDialog from "../CreditsDialog/CreditsDialog";

const Footer = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen((prevstate) => !prevstate);
  };
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <p className={styles.text}> © 2023 Tabassum Mulla</p>
          <p className={styles.credits} onClick={handleClickOpen}>
            Credits
          </p>
        </div>

        <div className={styles.content}>
          <Coffee />
          <a href="https://www.instagram.com/tvbs_">
            <InstagramIcon fontSize="large" className={styles.instagramIcon} />
          </a>
          <a href="https://www.linkedin.com/in/tabassummulla/">
            <LinkedInIcon fontSize="large" className={styles.linkedInIcon} />
          </a>
          <a href="mailto:tabassum.mulla7@yahoo.co.uk">
            <MailIcon fontSize="large" className={styles.mailIcon} />
          </a>

          {open && <CreditsDialog open={open} handleClose={handleClose} />}
        </div>
      </div>
    </div>
  );
};

export default Footer;
