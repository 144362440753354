import React from "react";
import { Button } from "@mui/material";
import ProfilePic from "../assets/images/profile.png";
import Typewriter from "typewriter-effect";
import { useSelector } from "react-redux";
import styles from "../style/HomePage.module.scss";
import classnames from "classnames";
import CV from "../assets/CV.pdf";

const Home = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.profileImageContainer}>
          <img
            className={styles.profileImage}
            src={ProfilePic}
            alt="profilepic"
          />
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>
            My name is Tabassum Mulla <span className={styles.wave}>👋🏼</span>{" "}
          </h1>{" "}
          <Typewriter
            options={{
              strings: [
                "Software Engineer By Profession",
                "Creative UX Designer",
                "Self Taught Digital Artist",
                "Travel Junkie at Heart",
              ],
              autoStart: true,
              loop: true,
            }}
          />{" "}
        </div>
        <div className={styles.actions}>
          <Button
            target="_blank"
            download
            href={CV}
            variant="contained"
            size="Large"
            className={styles.buttoncta}
          >
            Download CV
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Home;
