import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base";
import styles from "../ContactForm/ContactForm.module.scss";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { emailRegex, emailConfig, successMessage } from "../../utils/Contact";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";
import classnames from "classnames";

const ContactForm = () => {
  const [formInput, setFormInput] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [success, setSuccess] = useState(false);
  const form = useRef();
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        emailConfig.serviceID,
        emailConfig.templateID,
        form.current,
        emailConfig.publicKey
      )
      .then(
        () => {
          setFormInput({ email: "", name: "", subject: "", message: "" });
          setSuccess(true);
          setInvalidEmail(true);
        },
        () => {
          setFormInput({ email: "", name: "", subject: "", message: "" });
          setSuccess(false);
          setInvalidEmail(true);
        }
      );
  };

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const valid = isEmailValid(email);
    if (valid) {
      setInvalidEmail(false);
      setFormInput((prevState) => ({
        ...prevState,
        email: email,
      }));
    } else {
      setInvalidEmail(true);
    }
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setFormInput((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const message = successMessage(formInput.name);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {success ? (
          <div className={styles.success}>
            {/* <img src=""></img> */}
            <p className={styles.successMessage}>{message}</p>
          </div>
        ) : (
          <form
            className={styles.form}
            ref={form}
            value={formInput}
            id="contact-form"
            onSubmit={handleSubmit}
          >
            <div className={styles.contactMessage}>
              <p>
                If you have any questions please feel free to drop me a line. If
                you don't get an answer immediately, I might just be travelling
                or in the middle of nowhere 😃. I'll get back to you soon as I
                can. That's a promise!{" "}
              </p>
            </div>
            <div className={styles.formFields}>
              <div
                className={classnames(styles.section, {
                  [styles.sectionDark]: isDarkMode,
                })}
              >
                <TextField
                  id="name"
                  name="name"
                  sx={{ width: "100%" }}
                  value={formInput.name}
                  label="Name"
                  variant="filled"
                  onChange={handleInput}
                />
                <TextField
                  name="email"
                  id="email"
                  inputProps={{ type: "email" }}
                  sx={{ width: "100%" }}
                  value={formInput.email}
                  label="Email"
                  variant="filled"
                  onChange={handleEmailChange}
                />
              </div>
              <div
                className={classnames(styles.midsection, {
                  [styles.midsectionDark]: isDarkMode,
                })}
              >
                <TextField
                  className={styles.subject}
                  name="subject"
                  id="subject"
                  value={formInput.subject}
                  sx={{ width: "100%" }}
                  label="Subject"
                  variant="filled"
                  onChange={handleInput}
                />
              </div>
              <div className={styles.subsection}>
                <TextareaAutosize
                  className={styles.message}
                  id="message"
                  value={formInput.message}
                  onChange={handleInput}
                  name="message"
                  sx={{ width: "100%" }}
                  minRows={4}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                disabled={invalidEmail}
                type="submit"
                variant="contained"
                className={styles.sendButton}
              >
                Lets Talk
                <SendIcon fontSize="medium" />
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
