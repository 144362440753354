import React, { useState } from "react";
import styles from "./Navbar.module.scss";
import { NavLink } from "react-router-dom";
import DarkModeToggle from "react-dark-mode-toggle";
import { useSelector, useDispatch } from "react-redux";
import {
  switchOnDarkMode,
  switchOffDarkMode,
} from "../../reducers/DarkModeReducer";
import classnames from "classnames";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const Header = () => {
  return (
    <div className={styles.header}>
      <a href="/" className={styles.name}>
        TabassumMulla.
      </a>
    </div>
  );
};

const Navbar = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  const [showLinks, setShowLinks] = useState(false);

  const links = [
    { pathname: "/", label: "Home" },
    { pathname: "/about", label: "About" },
    { pathname: "/experience", label: "Experience" },
    { pathname: "/contact", label: "Contact" },
  ];

  const handleMenuClick = () => {
    setShowLinks((prevState) => !prevState);
  };

  const hideLinks = () => {
    setShowLinks(false);
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.content}>
        <Header />
        <nav className={styles.desktop}>
          {links.map((e, i) => (
            <NavLink to={e.pathname} activeclassname="is-active" key={i}>
              {e.label}
            </NavLink>
          ))}{" "}
          <DarkModeToggle
            onChange={() =>
              dispatch(isDarkMode ? switchOffDarkMode() : switchOnDarkMode())
            }
            className={isDarkMode ? styles.toggleDark : styles.toggle}
            checked={isDarkMode}
            size={80}
          />
        </nav>
        <div className={styles.mobile}>
          <IconButton onClick={handleMenuClick}>
            <MenuIcon fontSize="large" className={styles.menuIcon} />
          </IconButton>

          <IconButton
            onClick={() =>
              dispatch(isDarkMode ? switchOffDarkMode() : switchOnDarkMode())
            }
          >
            {isDarkMode ? (
              <DarkModeIcon className={styles.darkModeMobile} />
            ) : (
              <LightModeIcon className={styles.lightModeMobile} />
            )}
          </IconButton>
        </div>
      </div>
      {showLinks && (
        <div className={styles.links}>
          {links.map((e, i) => (
            <nav className={styles.linkContainer}>
              <NavLink
                onClick={hideLinks}
                to={e.pathname}
                className={styles.mobileLink}
                activeclassname="is-active"
                key={i}
              >
                {e.label}
              </NavLink>
            </nav>
          ))}{" "}
        </div>
      )}
    </div>
  );
};

export default Navbar;
