import React from "react";
import { skills } from "../utils/About";
import { useSelector } from "react-redux";
import SkillsMatrix from "../components/Skills/SkillsMatrix";
import styles from "../style/About.module.scss";
import classnames from "classnames";
import ProfilePic from "../assets/images/profile.png";
const About = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.content}>
        <div className={styles.profileImageContainer}>
          <img
            className={styles.profileImage}
            src={ProfilePic}
            alt="profilepic"
          />
        </div>
        <div className={styles.section1}>
          <h1 className={styles.title}>/ About Me</h1>
          <p className={styles.text}>
            I'm Tabs! A Software Engineer based in England. I am passionate
            about all things tech and love to design and build accessible and
            human centric products and solutions for users and businesses . I
            have 4 years of experience and would best like to describe myself as
            a <b>FullStack developer</b> with expertise in <b>React</b> and{" "}
            <b>Node.js</b>. I have worked in Consulting and In-house Companies.
            My current role is with{" "}
            <a
              className={styles.link}
              href="https://www.linkedin.com/company/utilitywarehouse/"
            >
              Utility Warehouse
            </a>
            .
          </p>{" "}
          <div className={styles.subsection}>
            <SkillsMatrix skills={skills} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
