import React from "react";
import styles from "../style/Contact.module.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";
import ContactForm from "../components/ContactForm/ContactForm";

const Contact = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.content}>
        <h1 className={styles.title}>/ Contact</h1>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
