import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home.jsx";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { Routes, Route } from "react-router-dom";
import "./style/App.scss";
import Experience from "./pages/Experience";
import Footer from "./components/Footer/Footer";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);
  return loading === false ? (
    <div className="app-container">
      <Navbar />
      <Routes>
        {" "}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  ) : (
    <div className="loader">
      <div className="load-text">
        <div className="loaded-text">H</div>
        <div className="loading-text">ello</div>
      </div>
    </div>
  );
};

export default App;
