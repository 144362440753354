import React from "react";
import ExpandableExperienceCard from "../components/ExpandableCard/ExpandableExperienceCard";
import { exp } from "../utils/Experience/index";
import styles from "../style/Experience.module.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";

const Experience = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.content}>
        <h1 className={styles.title}>/ Experience</h1>
        <div className={styles.section}>
          {exp.map((e, i) => (
            <ExpandableExperienceCard
              key={i}
              id={e.id}
              text={e.text}
              logo={e.logo}
              location={e.location}
              website={e.website}
              title={e.title}
              content={e.content}
              link={e.link}
              skills={e.skills}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Experience;
